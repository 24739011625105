import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Kinesiotaping</title>
					<meta name="description" content={'Curso de Kinesiotaping'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE KINESIOTAPING</h2>
				<div className='image-grid'>
					<div><img src='/media/img/kinesiotaping/1.jpg' /></div>
					<div><img src='/media/img/kinesiotaping/2.jpg' /></div>
					<div><img src='/media/img/kinesiotaping/3.jpg' /></div>
					<div><img src='/media/img/kinesiotaping/4.jpg' /></div>
				</div>
				<div className="content-block">
					<p>La ténica del kinesiotaping fue desarrollada en 1973, por Kenzo Kate, quiropráctico japonés. Creó un material al que llamó Kinesio Tape que sirve de soporte a los músculos, elimina la congestión vascular y linfática, y corrige los problemas articulares dejando libre el movimiento. El tape utiliza el proceso de curación natural del cuerpo, activando el sistema circulatorio y neurológico.</p>
					<p>El kinesiotaping da soporte y estabilidad a las articulaciones y músculos sin afectar a la amplitud del movimiento. Es una técnica basada en el proceso de autocuración del cuerpo, donde el tape tira de la piel para aumentar el espacio entre esta y los músculos; al reducir la presión localmente hace que se aumente la circulación, el drenaje linfático y que disminuya la irritación de los receptores nerviosos cutáneos. Como resultado el tape reduce el dolor, el espasmo muscular y la inflamación y por lo tanto acelera el proceso natural de curación del cuerpo. Las técnicas de aplicación no son complicadas, esta debe ser entendida y aprendida en su conjunto para asegurar óptimos resutados tanto terapéuticos como preventivos.</p>
					<br/>
					<h3 className="header-title">Principaes efectos:</h3>
					<ul>
						<li>Corrige la función muscular: relaja los músculos tensionados y refuerza los débiles.</li>
						<li>Mejora la circulación sanguínea y linfática: disminuye la aculumación local de líquidos.</li>
						<li>Alivia el malestar.</li>
						<li>Recoloca las subluxaciones articulares producidas por tensiones musculares anormales, restaurando la función normal de las fascias y músculos.</li>
					</ul>
					<br/>
					<h3 className="header-title">Campos de aplicación:</h3>
					<ul>
						<li>Para todos los indicios de dolor.</li>
						<li>En la profilaxis.</li>
						<li>En post-operatorios y postraumáticas.</li>
						<li>Osteopatía.</li>
						<li>Quiromasaje.</li>
						<li>Neurología.</li>
						<li>Orotopedia.</li>
						<li>Geriatría.</li>
						<li>Ginecología.</li>
					</ul>
					<br/>
					<h3 className="header-title">En casos:</h3>
					<ul>
						<li>Dolor puntual o crónico.</li>
						<li>Problemas articulares.</li>
						<li>Problemas de columna.</li>
						<li>Tensiones musculares.</li>
						<li>Síndrome del túnel carpiano.</li>
						<li>Epicondilitis.</li>
						<li>Problemas menstruales.</li>
						<li>Corrección postural.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Ciclo continuo.</p>
						<p><b>Duración:</b> 1 fin de semana. 16 horas.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}