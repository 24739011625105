import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { Frontload } from 'react-frontload';
import { BrowserRouter } from 'react-router-dom'
import createStore from './redux/store.js';
import * as serviceWorker from './serviceWorker';

import './styles/foundation.scss';
import {App} from "./routes/App/App";
import { PROGRESSIVE_APP } from './config.js';

// Create a store and get back itself and its history object
const store = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <BrowserRouter>
      <Frontload noServerRender={true}>
        <App />
      </Frontload>
    </BrowserRouter>
  </Provider>
);

const root = document.querySelector('#top');

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}

if(PROGRESSIVE_APP)
{
  serviceWorker.register();
}
else
{
  serviceWorker.unregister();
}