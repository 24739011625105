
import React from "react";
import { Sticky } from 'react-sticky';
// import PropTypes from 'prop-types';
import "./top-bar.scss"
import { FixedBlock } from "../FixedBlock/FixedBlock";


export class TopBar extends React.Component
{
	constructor(props)
  {
    super(props);
    this.bar = React.createRef()
	}
	
	componentDidMount ()
	{
		$(this.bar.current).foundation(); //eslint-disable-line no-undef
	}

	render()
	{
		
		return (
			<Sticky >
				{({style, isSticky, wasSticky, distanceFromTop, distanceFromBottom, calculatedHeight }) => (
					<div ref={this.bar} style={{...style, left:0}} className="top-bar-container">
						<div className={distanceFromTop<=-10?"top-bar is-stuck":"top-bar"} >
								{this.props.children}
						</div>
					</div>
				)}
			</Sticky>
		);
	}
}

export class FixedTopBar extends React.Component
{
	render()
	{
		return (
			<FixedBlock className="top-bar-container">
				<div  style={{marginTop: "0px", top: "0px", width:"100%"}} className="top-bar is-stuck" >
					{this.props.children}
				</div>
			</FixedBlock>
		);
	}
}