import React from "react";
import {Helmet} from "react-helmet";

import touch57 from "../../assets/favicon/apple-touch-icon-57x57.png";
import touch114 from "../../assets/favicon/apple-touch-icon-114x114.png";
import touch72 from "../../assets/favicon/apple-touch-icon-72x72.png";
import touch144 from "../../assets/favicon/apple-touch-icon-144x144.png";
import touch60 from "../../assets/favicon/apple-touch-icon-60x60.png";
import touch120 from "../../assets/favicon/apple-touch-icon-120x120.png";
import touch76 from "../../assets/favicon/apple-touch-icon-76x76.png";
import touch152 from "../../assets/favicon/apple-touch-icon-152x152.png";
import png196 from "../../assets/favicon/favicon-196x196.png";
import png96 from "../../assets/favicon/favicon-96x96.png";
import png32 from "../../assets/favicon/favicon-32x32.png";
import png16 from "../../assets/favicon/favicon-16x16.png";
import png128 from "../../assets/favicon/favicon-128.png";
import ms114 from "../../assets/favicon/mstile-144x144.png";
import ms70 from "../../assets/favicon/mstile-70x70.png";
import ms150 from "../../assets/favicon/mstile-310x150.png";
import ms310 from "../../assets/favicon/mstile-310x310.png";
import ms310s from "../../assets/favicon/mstile-310x310.png";

export class Favicomatic extends React.Component
{
	// GENERATE ICONS HERE: http://www.favicomatic.com/ (Evert damn size opt)
	// PUT EVERYTHING HERE: /assets/favicon/
	// ADD favicon.ico TO PUBLIC
	render()
	{
		return (
				<Helmet>
						<link rel="apple-touch-icon-precomposed" sizes="57x57" href={touch57} />
						<link rel="apple-touch-icon-precomposed" sizes="114x114" href={touch114} />
						<link rel="apple-touch-icon-precomposed" sizes="72x72" href={touch72} />
						<link rel="apple-touch-icon-precomposed" sizes="144x144" href={touch144} />
						<link rel="apple-touch-icon-precomposed" sizes="60x60" href={touch60} />
						<link rel="apple-touch-icon-precomposed" sizes="120x120" href={touch120} />
						<link rel="apple-touch-icon-precomposed" sizes="76x76" href={touch76} />
						<link rel="apple-touch-icon-precomposed" sizes="152x152" href={touch152} />
						<link rel="icon" type="image/png" href={png196} sizes={"196x196"} />
						<link rel="icon" type="image/png" href={png96} sizes="96x96" />
						<link rel="icon" type="image/png" href={png32} sizes="32x32" />
						<link rel="icon" type="image/png" href={png16} sizes="16x16" />
						<link rel="icon" type="image/png" href={png128} sizes="128x128" />
						<meta name="application-name" content="&nbsp;"/>
						<meta name="msapplication-TileColor" content="#FFFFFF" />
						<meta name="msapplication-TileImage" content={ms114} />
						<meta name="msapplication-square70x70logo" content={ms70} />
						<meta name="msapplication-square150x150logo" content={ms150} />
						<meta name="msapplication-wide310x150logo" content={ms310} />
						<meta name="msapplication-square310x310logo" content={ms310s} />
				</Helmet>
			);
	}
}
