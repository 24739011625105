import React from 'react';
import { listifyChildren } from './MenuUtils';

export class DropdownMenu extends React.Component
{

	constructor(props)
	{
		super(props);
		this.dropdownMenu = React.createRef();
	}

	componentDidMount ()
	{
		$(this.dropdownMenu.current).foundation(); //eslint-disable-line no-undef
	}

	render()
	{
		let {children, className="", vertical=false, ...props} = this.props;
		className+=vertical?"menu dropdown vertical":"menu dropdown"
		return (
			<ul ref={this.dropdownMenu} {...props} className={className} data-dropdown-menu  >
				{listifyChildren(children)}
			</ul>
		);
	}
}

