import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Osteopatía Cráneo-Sacra</title>
					<meta name="description" content={'Curso de Osteopatía Cráneo-Sacra'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE OSTEOPATÍA CRÁNEO-SACRA</h2>
				<div className='image-grid'>
					<div><img src='/media/img/craneal-sacra/1.jpg' alt='image 1' /></div>
					<div><img src='/media/img/craneal-sacra/2.jpg' alt='image 2' /></div>
					<div><img src='/media/img/craneal-sacra/3.jpg' alt='image 3' /></div>
				</div>
				<div className="content-block">
					<p>Este curso desarrolla sus conocimientos teóricos y prácticos en un volumen de 250 páginas, ampliamente cargado de ilustraciones tanto de <b>ANATOMÍA DESCRIPTIVA</b> como <b>FUNCIONAL</b>, así como de las técnicas manipulativas de escucha y corrección de todos y cada uno de los huesos que comprenden la biología y la biomecánica de la estructura <b>CRÁNEO-SACRA</b>.</p>
					<p>Obviamente también de las estructuras blandas, <b>duramadre</b>, <b>aracnoides</b>, <b>piamadre</b>, las membranas de tensión recíproca (<b>Hoz del Cerebro</b>, <b>Hoz del Cerebelo</b>, <b>Tienda del Cerebelo</b>), actuantes todas ellas sobre todos y desde todos los huesos del cuerpo a través de todas las fascias impulsados por las fluctiaciones del L.C.R. (líquido cefalorraquídeo)</p>
					<p><b>Esta medicina global presenta 3 grandes principios:</b></p>
					<ul>
						<li>Unidad del cuerpo</li>
						<li>Interrelación estructura/función</li>
						<li>Autocuración</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<ul>
						<li>Primario. Principios cráneo-sacros.</li>
						<li>Clasificación y referencias palpatorias de las suturas.</li>
						<li>Descripción de los orificios de la base.</li>
						<li>Vascularización craneal.</li>
						<li>Las membranas de presión recíproca.</li>
						<li>El diagnóstico osteopático craneal.</li>
						<li>El movimiento respiratorio.</li>
						<li>Esfenoides occipital y sacro.</li>
						<li>Sincondrosis esfeno-basilar S.E.B.</li>
						<li>Fisiología y test palpatorios de la movilidad.</li>
						<li>Las diferentes lesiones y sus influencias.</li>
						<li>Normalizaciones prácticas.</li>
						<li>Los nervios craneales y las fascias.</li>
						<li>Patología del sistema nervioso.</li>
						<li>Temporal y parietal.</li>
						<li>Transtornos del equilibrio y audición, vértigos, otitis.</li>
						<li>Frontal y parte superior de la cara.</li>
						<li>Transtornos de la visión, sinusitis, fosas nasales.</li>
						<li>Cara inferior, oclusión descripción, referencias.</li>
						<li>Introducción a los problemas de oclusión dentaria.</li>
						<li>Lesiones intraóseas de la base craneal.</li>
						<li>Pediatría osteopática, patología.</li>
						<li>Relaciones transtornos, disfinciones craneales.</li>
						<li>Valoración técnica, pretecnología, práctica.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
					<p><b>Comienzo:</b> Ciclo continuo.</p>
					<p><b>Duración:</b> 12 meses (aprox.) 96 horas.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Modalidad de fin de semana (1 vez al mes)</p>
							<p>- Sábado de 10:00 a 14:00 y 16:00 a 20:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}