import React from "react";
import Helmet from 'react-helmet';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}
	
	render()
	{
		return (
			<div className='slim-content contact-page'>
				<Helmet>
					<title>Contacto</title>
					<meta name="description" content={'Contacto'} />
				</Helmet>
				<h1 className='header-title'>Contáctanos</h1>
				
				<div className='content-block'>
					<p>Para cualquier consulta puedes contactar con nosotros a través de nuestros teléfonos, correo electrónico o visitando nuestras instalaciones.</p>

					<p>Recuerda que hemos cambiado de ubicación y ya no estámos en calle Barcelona.</p>
					<div className='contact-data'>
						<h5><FontAwesomeIcon icon={['fas', 'phone']} size='2x' /> <a href='tel:886302455'>886 302 455</a> / <a href='tel:622397490'>622 397 490</a> / <a href='tel:600425434'>600 425 434</a></h5>
						<h5><FontAwesomeIcon icon={['fas', 'envelope-open-text']} size='2x' /> <a href='mailto:quimas.vigo@gmail.com'>quimas.vigo@gmail.com</a></h5>
						<h5><FontAwesomeIcon icon={['fas', 'map-marked']} size='2x' /> <a href='https://goo.gl/maps/beqXEaAbCce5hPNS7' target='_blank'>Avenida de Castrelos 55 Bajo, 36210, Vigo, Pontevedra</a></h5>
					</div>
					<iframe className='geomap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d878.519682044029!2d-8.732555319791508!3d42.214953187536636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2589e3dcbb585f%3A0xb0ba21d77aa323c4!2sAv.+de+Castrelos%2C+55%2C+36210+Vigo%2C+Pontevedra!5e0!3m2!1ses!2ses!4v1564135057193!5m2!1ses!2ses" width="100%" height="450" frameBorder="0" allowFullScreen></iframe>
				</div>
				<div className='social-media'>
					<h3 className='header-title'>Nuestras redes sociales</h3>
					<a href='https://www.facebook.com/quimas.quimas.39' target='_blank'><FontAwesomeIcon icon={['fab', 'facebook']} size='5x' /></a>
					<a href='https://www.instagram.com/quimasvigo/?hl=es' target='_blank'><FontAwesomeIcon icon={['fab', 'instagram']} size='5x' /></a>
				</div>
			</div>
		);
	}
}