import { isUndefined, isNullOrUndefined, isFunction, isArray } from "util";
import { connect } from "react-redux";
import QueryString from "query-string";
import deepEqual from "deep-equal";
import { isString } from "util";
import Base64 from "base-64";
import { getCookie } from "./CookieHandler";

export const isServer = !(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function validatePassword(password, repeatedPassword)
{
  if (password !== repeatedPassword) {
    alert("La contraseñas no coinciden!");

    return false;
  }
  
  if (!isNullOrUndefined(password)) {
    let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;

    var regResult = reg.exec(password);

    if (isNullOrUndefined(regResult)) {
      alert(
        "Las contraseñas ha de tener como mínimo: 8 caracteres, números, letras y símbolos"
      );
      return false;
    }
  }
  else
  {
    return false;
  }
  
  return true
}

export function getPaginationFromCookie(cookieName) {
	const storedPagination = decode(getCookie(cookieName));

	if (typeof storedPagination !== "undefined") {
		// ONLY PASS THE ONES WE WANT, TO AVOID INJECTION
		return getDefaultPagination({
			orderBy: storedPagination.orderBy,
			sortOrder: storedPagination.sortOrder,
			rowsPerPage: storedPagination.rowsPerPage
		});
	}

	return getDefaultPagination();
}

export function getDefaultPagination(options) {
	return {
		orderBy: "name",
		sortOrder: "ASC",
		page: 1,
		rowsPerPage: 10,
		totalResults: 0,
		...options
	};
}

export function cssUrl(url) {
  return isServer ? `static${url}` : url;
}

export function validStatus(status) {
  return status < 300 && status >= 200;
}

export function download(file, filename) {
  // IE10+
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, filename);
  }
  // Others
  else {
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function getWindowSize() {
  if (isServer) {
    return { width: 0, height: 0 };
  }

  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName("body")[0];

  return {
    width: w.innerHeight || e.clientHeight || g.clientHeight,
    height: w.innerWidth || e.clientWidth || g.clientWidth
  };
}

export function phpEncodeUrl(url) {
  return encodeURI(url).replace(/%20/g, "+");
}

export function parseQuery(query) {
  return QueryString.parse(query);
}

export function parseUrl(url) {
  return QueryString.parseUrl(url);
}

export function stringifyQuery(query, format = { arrayFormat: "index" }) {
  return QueryString.stringify(query, format);
}

export function leftZeroes(number, length = 2) {
  number = number / Math.pow(10, length);
  return number
    .toFixed(2)
    .toString()
    .split("0.")[1];
}

export function phpDecodeUrl(url) {
  return decodeURI(url.replace(/\+/g, "%20"));
}

export function addToNewState(newState, addition) {
  return state => {
    const firstState =
      typeof newState === "function"
        ? newState(state)
        : typeof newState === "object"
          ? newState
          : {};

    const secondState =
      typeof addition === "function"
        ? addition(state)
        : typeof addition === "object"
          ? addition
          : {};

    return { ...firstState, ...secondState };
  };
}

export function encode(data) {
  return Base64.encode(JSON.stringify(data).replace(/</g, "\\u003c"));
}

export function decode(data) {
  if (isUndefined(data)) return undefined;

  const c = JSON.parse(Base64.decode(data));

  return c;
}

export function combineIntoObject(keys, values) {
  return values.reduce(
    (obj, value, index) => ({ ...obj, [keys[index]]: value }),
    {}
  );
}

export function safeConnect(mapStateToProps, mapDispatchToProps) {
  if (typeof mapDispatchToProps !== "function") {
    mapDispatchToProps = () => {
      return {};
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  );
}

export function getResponsiveness(props) {
  let { small, medium, large, up, down, only, ...otherProps } = props;

  let responsiveness = { small, medium, large, up, down, only };

  Object.keys(responsiveness).forEach(key => {
    if (responsiveness[key] === undefined) delete responsiveness[key];
  });

  return { responsiveness: responsiveness, props: otherProps };
}

export function inverseResponsiveness(r) {
  let response = {};

  if ((r.up && r.small) || (r.down && r.large)) {
    return [];
  }

  if (r.medium) {
    if (r.up) {
      response.down = true;
      response.small = true;
    } else if (r.down) {
      response.large = true;
      response.up = true;
    } else {
      return [
        {
          small: true,
          down: true
        },
        {
          large: true,
          up: true
        }
      ];
    }
  } else if ((r.up || r.only) && r.small) {
    response.down = true;
    if (r.medium) response.small = true;
    if (r.large) response.medium = true;
  } else if ((r.down || r.only) && r.large) {
    response.up = true;
    if (r.small) response.medium = true;
    if (r.medium) response.large = true;
  }

  if (Object.keys(response).length > 0) {
    return [response];
  }

  return [];
}

export function spliceEdits(original, edited, undefinedValue, setUndefined) {
  let keys = [...Object.keys(original), ...Object.keys(edited)];

  let final = {};

  keys.forEach(key => {
    if (edited[key] !== original[key]) {
      if (isNullOrUndefined(edited[key])) {
        if (isFunction(setUndefined)) {
          final[key] = setUndefined(key);
        } else {
          final[key] = undefinedValue;
        }
      } else {
        final[key] = edited[key];
      }
    }
  });

  return final;
}

export function CUDify(original, update, keys) {
  let updatedGroup = { add: [], update: [], delete: [] };

  keys = isArray(keys) ? keys : [keys];

  update.forEach(uElement => {
    let target = containsCombination(original, uElement, keys);
    if (target.found) {
      if (!deepEqual(target.item, uElement)) {
        updatedGroup.update.push(uElement);
      } else {
        // console.log("Skipped",uElement,target.item,deepEqual(target.item,uElement ))
      }
    } else {
      updatedGroup.add.push(uElement);
    }
  });

  original.forEach(oElement => {
    let target = containsCombination(update, oElement, keys);
    if (!target.found) {
      updatedGroup.delete.push(oElement);
    }
  });

  return updatedGroup;
}

export function containsCombination(group, target, keys) {
  let index = -1;
  let item = group.find(function(oElement, oIndex) {
    index = oIndex;
    let found = true;
    keys.forEach(key => {
      found = oElement[key] === target[key] ? found : false;
    });
    return found;
  });

  return { found: typeof item !== "undefined", index: index, item: item };
}

export function matchLight(color) {
  let rbgColor = hexToRgb(color);
  let perceivedBrightness =
    ((0.299 * rbgColor.r + 0.587 * rbgColor.g + 0.114 * rbgColor.b) / 255) *
    100;

  return perceivedBrightness >= 50 ? "#0a0a0a" : "#fefefe";
}

export function hexToRgb(hex, defaultRgb = "#fefefe") {
  if (!isString(hex)) return defaultRgb;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

export function chooseText(texts, context) {
  if (typeof texts !== "object") {
    return texts;
  }
  if (typeof texts[context.lang] !== "undefined") {
    return texts[context.lang];
  }
  if (typeof texts[context.defaultLang] !== "undefined") {
    return texts[context.defaultLang];
  }
  return texts[Object.keys(texts)[0]];
}

export function ifDefined(value, elseValue) {
  if (!isUndefined(value)) {
    if (typeof value === "function") {
      return value();
    }
    return value;
  }

  if (!isUndefined(elseValue)) {
    if (typeof elseValue === "function") {
      return elseValue();
    }
    return elseValue;
  }
  return;
}
