import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Drenaje Linfático</title>
					<meta name="description" content={'Curso de Drenaje Linfático'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE DRENAJE LINFÁTICO</h2>				
				<div className="content-block">
					<p>El drenaje linfático es descubierto por el Dr. Emil Vodder en 1932.</p>
					<p>En el congreso de 1936 (Santé-Beauté) en París, hace su primera comunicación pública del método.</p>
					<p>Ahora, después de más de 60 años de divulgación y práctica los hechos han probado que se trata de una terapia eficaz para la eliminación de edemas, hematomas, estimulación de determinadas funciones inmunológicas, activación del metabolismo celular y regeneración de los tejidos; también se debe mencionar la profunda acción que la técnica bien trabajada ejerce sobre el sistema nervioso y la disminución del dolor.</p>
					<p>El drenaje linfático puede ser aplicado en diversas patologías: luxaciones, fracturas óseas, reumatismo, poliartritis, osteoporosis, transtornos venosos, linfoedemas de las extremidades, pre y post-operatorios, estados catarrales, sinusitis, neuralgias, alergias, cicatrices, quemaduras, etc. así como imprescindibles en el campo de la belleza y la cirugía reparadora. Descubierto epíricamente por el Dr. Vodder, biólogo y fisioterapeuta, ayudando a su esposa Estrid, fisioterapeuta, controlado científicamente por profesores y médicos como Mislin, Foldi, Kuhnke, etc. este método (esencialmente manual, ningún aparato ha podido igualarlo) comprende maniobras dulces, lentas, rítmicas, en espiral, presiones ligeras, etc.</p>
					<p>Nos encontramos ante una ciencia y un arte: ante un método de contacto, terapeuta, paciente, realmente blando, respetuoso, limpio, sorprendentemente eficaz. El estímulo fuerte inhibe y el estímulo suave potencia.</p>
					<p>En un reciente congreso de Medicina Deportiva en Barcelona, una de las conclusiones a las que se llegó es que el DLM es la terapia dmás indicada para lesiones deportivas y para la recuperación del deportista tras la competición.</p>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<p>Este es un resumen de los módulos que componen este curso en el cual se explica ampliamente que es y cuales son las aplicaciones terapéuticas de DLM. Sus contraindicaciones. Diferencias de esta técnica terapéutica con otros métodos, la aplicación sobre las distintas zonas corporales, como cabeza, cuello, cara, tórax, abdomen, extremidades, etc.</p>
					<p>La fisiología y anatomía de los vasos y grandes vasos linfáticos los beneficios de esta terapia sobre el SNV, sobre la musculatura, los tratamientos de los edemas linfoestaticos y/o linfodinamicos.</p>
					<p>Los buenos resultados de la aplicación del DLM en esguinces, contracturas e inflamaciones crónicas, sobre el sistema respiratorio, tracto intestinal, sinusitis, alergias y enfermedades auto-alérgicas, etc.</p>
					<ul>
						<li>Teoría.</li>
						<li>Práctica.</li>
						<li>Sistema de vasos sanguíneos.</li>
						<li>El tejido conjuntivo.</li>
						<li>El sistema linfático.</li>
						<li>Eficacia sobre el sistema nervioso.</li>
						<li>Musculatura del esqueleto y vasos.</li>
						<li>Efecto sobre el sistema defensivo.</li>
						<li>Líneas generales de aplicación.</li>
						<li>Edemas.</li>
						<li>Enfermedades y su tratamiento.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Ciclo continuo.</p>
						<p><b>Duración:</b> 9 meses.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}