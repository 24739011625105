import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Vendaje Funcional</title>
					<meta name="description" content={'Curso de Vendaje Funcional'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE VENDAJE FUNCIONAL</h2>
				<div className='image-grid'>
					<div><img src='/media/img/functional-bandage/1.png' alt='image 1' /></div>
					<div><img src='/media/img/functional-bandage/2.jpg' alt='image 2' /></div>
				</div>
				<div className="content-block">
					<p>En este curso se tratarán todas las estructuras del aparato locomotor ya sean activas ó pasivas. Así como las lesiones que sufran o puedan sufrir estas estrucuras. Pudiendo ser su aplicación tanto en el campo preventivo como en el paliativo.</p>
					<p>En este curso se realizarán las prácticas de vendaje con vendas elásticas e inelásticas y los complementos que requiera el caso que se deba tratar, como almohadillados, prevendajes, etc.</p>
				</div>
				<div className="content-block">
					<h3 className="header-title">Tratamientos más comunes</h3>
					<p className='tags'>
						<span>Esguinces</span><span>Bursitis</span><span>Tendinitis</span><span>Laxitud ligamentaria</span><span>Correctivo</span><span>Rotura de fibras</span><span>Sobrecargas</span>
						<span>Lateralización</span><span>Subluxación</span>
					</p>
					<p className='tags'>
						<span>Cadera</span><span>Rodilla</span><span>Tobillo</span><span>Tarso</span><span>Metatarso</span><span>Dedos pie</span>
						<span>Hombro</span><span>Codo</span><span>Muñeca</span><span>Carpo</span><span>Metacarpo</span><span>Dedos mano</span>
					</p>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Ciclo continuo.</p>
						<p><b>Duración:</b> 1 fin de semana. 12 horas.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Modalidad de fin de semana</p>
							<p>- Sábado de 10:00 a 14:00 y 16:00 a 20:00</p>
							<p>- Domingo de 10:00 a 14:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}