import React from 'react';
import PropTypes from 'prop-types';

import './FixedBlock.scss'

export class FixedBlock extends React.Component
{
	state={style:{paddingTop:'0px'},loaded:false}

	wrapper = React.createRef();

	componentDidMount()
	{
		if(document.readyState === 'complete')
		{
			this.onLoad();
		}
		else
		{
			window.addEventListener("load", this.onLoad, {
				capture: true,
				passive: true
			} );
		}

	}

	componentWillUnmount()
	{
		window.removeEventListener("load", this.onLoad);
	}

	onLoad = () =>
	{

		if(typeof this.wrapper.current !== 'undefined')
		{
			// console.log(this.wrapper.current,this.wrapper.current.offsetHeight);
			this.setState({loaded:true,style:{paddingTop:`${this.wrapper.current.offsetHeight}px`}})
		}

		window.removeEventListener('load', this.onLoad);
	}

	render()
	{
		const {children, className='', ...props} = this.props;
		return (
			<div
				{...props}
				ref={this.wrapper}
				className={`fixed-block ${this.state.loaded?" loaded":''} ${className}`}
			>
				{children}
				<div className='fixed-spacer' style={this.state.style}> </div>
			</div>
		);
	}
}