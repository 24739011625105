import React from "react";
import Helmet from 'react-helmet';

import logo from "../../assets/logo.png";
import { CoverWithViewer } from "../../components/ImageViewer/CoverWithViewer";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}
	
	render()
	{
		return (

			<div className='slim-content us-page'>
				<Helmet>
					<title>Nosotros</title>
					<meta name="description" content={'Nosotros'} />
				</Helmet>
				<h1 className='header-title'>Nosotros</h1>
				<div className='content-block'>
					<p><b>QUIMAS</b> nace en Cantabria en el año 1987 a partir de la estrecha relación de su fundador tenía con el mundo del deporte cantabro, así como la necesidad de buenos profesionales en el campo de las terapias manuales aplicadas a deportistas. En sus comienzos colabora eficazmente con clubes de ciclistas juveniles y diversos balnearios de la provincia. En el marco de Plan de Balneoterapia de Cantabria para el INSERSO donde se desarrolla eficazmente 2.265 tratamientos lo que desemboca en la organización de los primeros cursos de preparación para el personal de INSALUD.</p>
					<p>En 1990 <b>QUIMAS</b> amplía horizontes hacia Gijón y Oviedo, debido en parte a la gran aceptación y prestigio alcanzados en Cantabria, sobre todo en el mantenimiento y preparación de deportistas de elite del note de España. Esto lleva a que un año más tarde la gran demanda surgida en Galicia necesitará del nacimiento de un nuevo centro en Vigo (1991) y posteriormente otro más en Orense (1996).</p>
					<p>En la actualidad, el centro <b>QUIMAS</b> está homologado por la Federación Politécnica Española de Diplomados. Diplomados por escuelas nacionales no estatales y escuelas extranjeras. Antigua Federación Nacional de Ignerieros Diplomados, constituida el 18 de febrero de 1931.</p>
				</div>
				<div className='separator'>
					<img src={logo} width='64' alt='' />
					<img src={logo} width='64' alt='' />
					<img src={logo} width='64' alt='' />
				</div>
				<div className='content-block'>
					<p><b>D. Joseba K. Mirones Oliva</b> Director de la "Escuela Politécnica de FEDINE" de Barcelona, para la delegación de Vigo Director del Centro de Terapias Alternativas QUIMAS.</p>
					<p>En 1985 comienza los estudios de Quiromasaje y Osteopatía Articular en el I.E.T.A. de Burdeos (Instituto Europeo de Terapias Alternativas) donde sigue su preparación en el campo de la Osteopatía Craneal (1988).</p>
					<p>En 1989 complementa sus conocimientos de Osteopatía Articular en GAIA-Madrid, Delegación de FEDINE.</p>
					<p>En la escuela de FEDINE de Barcelona realiza los estudios de "Educación Física Especial y Gimnasia Correctiva (1990).</p>
					<p>Es autor y director del primer curso de Quiromasaje homologado por la Xunta de Galicia, tanto en programa como en contenidos.</p>
					<p>Es autor y director del programa y curso de Osteopatía, manual desarrollado en más de 1.300 páginas con aprox. 1.200 ilustraciones de anatomía, fisiología, patología y técnicas manipulativas de diagnóstico y normalización de todas y cada una de las articulaciones y estructuras.</p>
					<p>Experto colaborador en Cursos de Formación Ocupacional.</p>
					<p>Diplomado en "Metodología Didáctica para la Formación Profesional".</p>
				</div>
				<div className='gallery-block'>
					<CoverWithViewer src="/media/img/home/1.jpg" />
					<CoverWithViewer src="/media/img/home/2.jpg" />
					<CoverWithViewer src="/media/img/home/3.jpg" />
					<CoverWithViewer src="/media/img/home/4.jpg" />
					<CoverWithViewer src="/media/img/home/5.jpg" />
					<CoverWithViewer src="/media/img/home/6.jpg" />
					<CoverWithViewer src="/media/img/home/7.jpg" />
					<CoverWithViewer src="/media/img/home/8.jpg" />
					<CoverWithViewer src="/media/img/home/9.jpg" />
					<CoverWithViewer src="/media/img/home/10.jpg" />
					<CoverWithViewer src="/media/img/home/11.jpg" />
					<CoverWithViewer src="/media/img/home/12.jpg" />
				</div>
			</div>
		);
	}
}