import React from 'react';
import PropTypes from 'prop-types';
import "./ImageViewer.scss"

export class CoverWithViewer extends React.Component
{
	state =
	{
		open:false
	}

	static propTypes =
	{
		src: PropTypes.string.isRequired,
		thumb: PropTypes.string
	}
	

	render()
	{
		let {src, thumb=this.props.src, ...props} = this.props;
		return (
			<div className="cover-with-viewer">
				<div className="cover" style={{backgroundImage:`url(${thumb})`}} onClick={()=>this.setState({open:true})} {...props} />
				{this.state.open &&
					<div className="image-viewer-overlay" onClick={()=>this.setState({open:false})} >
						{ // TODO: HANDLE ALT TEXT
							//eslint-disable-next-line jsx-a11y/alt-text
						}<img src={src} />
					</div>
				}
			</div>
		);
	}
}