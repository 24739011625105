import React from 'react';
import { isFunction } from "util";
import { SubMenu } from "./SubMenu";

export function listifyChildren(children)
{
	return children.map((child,i)=>{
		if(
				(isFunction(child.type) && child.type.name!==SubMenu.name)
				||
				(!isFunction(child.type) && child.type !== "li")
				)
		{
			return <li key={i} >{child}</li> ;
		}
		return child;
	});
	
}