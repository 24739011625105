import React from "react";
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import Home from '../Home/Home';

import { Pace } from "../../components/Pace/Pace";
import { FixedTopBar } from "../../components/TopBar/TopBar";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import logo from "../../assets/logo_B.png";
import { DropdownMenu } from "../../components/FoldableMenu/DropdownMenu";
import { HashLink as Link } from "react-router-hash-link";
import { SubMenu } from "../../components/FoldableMenu/SubMenu";
import Articular from "../Courses/Osteophaty/Articular";
import Contact from "../Contact/Contact";
import Us from "../Us/Us";
import CranealSacra from "../Courses/Osteophaty/CranealSacra";
import Other from "../Courses/Other";
import Visceral from "../Courses/Osteophaty/Visceral";
import Chiromassage from "../Courses/Chiromassage";
import LymphaticDrainage from "../Courses/LymphaticDrainage";
import Kinesiotaping from "../Courses/Kinesiotaping";
import FunctionalBandage from "../Courses/FunctionalBandage";
import ChinesseTraditionalMedicine from "../Courses/ChinesseTraditionalMedicine";
import { Favicomatic } from "../../components/Favicomatic/Favicomatic";
import { Footer } from "../../components/Footer/Footer";

import "./app.scss";

library.add(fas, fab);

export class App extends React.Component
{
	render()
	{
		return (
				<div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>
					<Helmet>
						<meta charset="UTF-8" />
    					<meta name="theme-color" content="#000000" />
						<meta name="viewport" content="width=device-width, shrink-to-fit=no, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
						<meta http-equiv="X-UA-Compatible" content="ie=edge" />
          				<meta content="text/html; charset=utf-8" http-equiv="content-type" />
						<link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
						<link href="https://fonts.googleapis.com/css?family=Bitter&display=swap" rel="stylesheet" />

						<title>Quimas - Escuela politécnica de terapias manuales</title>
						<meta name="description" content="Quimas - Escuela politécnica de terapias manuales y centro de masaje en Vigo." />
						<meta name="keywords" content="quimas, vigo, escuela, politecnica, homologada, fedine, centro, masaje, formacion, cursos, terapias, tratamientos" />
						<meta name="og:site_name" content="Quimas - Escuela politécnica de terapias manuales" />
						<meta name="og:description" content="Quimas - Escuela politécnica de terapias manuales y centro de masaje en Vigo." />
					</Helmet>
					<Favicomatic />
					<Pace ajax={true} />
					<div className="off-canvas-content" data-off-canvas-content >
						<FixedTopBar>
							<div className='logo-block'>
							<Link to="/">
								{/* <img src={logo} />
								<h3>Quimas</h3> */}
								<img src={logo} alt='logo' />
							</Link>
							</div>
							<div>
							<DropdownMenu>
								{/* <Link to="/">Inicio</Link> */}
								<SubMenu label="Cursos">
									<SubMenu label="Osteopatía">
										<Link to="/cursos/osteopatia/articular">Articular</Link>
										<Link to="/cursos/osteopatia/craneo-sacra">Cráneo-Sacra</Link>
										<Link to="/cursos/osteopatia/visceral">Visceral</Link>
									</SubMenu>
									<Link to="/cursos/quiromasaje">Quiromasaje</Link>
									<Link to="/cursos/drenaje-linfatico">Drenaje Linfático</Link>
									{/* <Link to="/cursos/">Reflexología</Link> */}
									<Link to="/cursos/kinesiotaping">Kinesiotaping</Link>
									<Link to="/cursos/vendaje-funcional">Vendaje Funcional</Link>
									<Link to="/cursos/medicina-tradicional-china">Medicina Tradicional China</Link>
									<Link to="/cursos/otros">Otros</Link>
								</SubMenu>
								<SubMenu label="Tratamientos">
									<Link to="/">Osteopatía</Link>
									<Link to="/">Masaje</Link>
									<Link to="/">Acupuntura</Link>
									<Link to="/">Acupuntura Estética</Link>
									{/* <Link to="/">Reiki</Link>
									<Link to="/">Flores de batch</Link> */}
								</SubMenu>
								<Link to="/nosotros">Nosotros</Link>
								<Link to="/contacto">Contacto</Link>
							</DropdownMenu>
							</div>
						</FixedTopBar>
						<Switch>
							<Route path="/cursos/osteopatia/articular" component={Articular} />
							<Route path="/cursos/osteopatia/craneo-sacra" component={CranealSacra} />
							<Route path="/cursos/osteopatia/visceral" component={Visceral} />
							<Route path="/cursos/quiromasaje" component={Chiromassage} />
							<Route path="/cursos/drenaje-linfatico" component={LymphaticDrainage} />
							<Route path="/cursos/kinesiotaping" component={Kinesiotaping} />
							<Route path="/cursos/vendaje-funcional" component={FunctionalBandage} />
							<Route path="/cursos/medicina-tradicional-china" component={ChinesseTraditionalMedicine} />
							<Route path="/cursos/otros" component={Other} />
							<Route path="/nosotros" component={Us} />
							<Route path="/contacto" component={Contact} />
							<Route component={Home} />
						</Switch>
					</div>
					<Footer copyrightHolder="Quimas" />
				</div>
		);
	}
}