import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';
import "./pace.scss"

/**
 * Includes the pace script, which shows a loading bar for async resources
 * More info : https://github.hubspot.com/pace/docs/welcome/
 * 
 * @export
 * @class Pace
 * @extends {React.Component}
 */
export class Pace extends React.Component
{
	static propTypes =
	{
		/** If true, each ajax request will show as a loading bar */
		ajax: PropTypes.bool
	}

	static defaultProps =
	{
		ajax:false
	}

	componentDidMount()
	{
		window.paceOptions = {
			elements: false,
			restartOnRequestAfter: this.props.ajax,
			ajax:this.props.ajax,
		}
	}

	render()
	{
		return (
				<Helmet>
          	<script src="/js/pace.min.js"></script>
				</Helmet>
			);
	}
}