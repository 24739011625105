import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Quiromasaje</title>
					<meta name="description" content={'Curso de Quiromasaje'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE QUIROMASAJE</h2>
				<div className='image-grid'>
					<div><img src='/media/img/chiromassage/1.jpg' alt='image 1' /></div>
					<div><img src='/media/img/chiromassage/2.jpg' alt='image 2' /></div>
					<div><img src='/media/img/chiromassage/3.jpg' alt='image 3' /></div>
				</div>
				<div className="content-block">
					<p>El quiromasaje  consiste en una serie de manipulaciones dirigidas al aparato músculo-esquelético, sistema circulatorio, tejido conectivo y órganos utilizando técnicas propioceptivas, miotensivas y reflejas con el fin de conseguir de las condiciones normales de los tejidos citados.</p>
					<p>La finalidad del curso es que los alumnos/as estén capacitados para atender a las personas que bien por motivos de:</p>
					<p>Trabajo, deporte, malestar por problemas degenerativos (reumatismos, etc.), discapacitados, minusválidos, estrés, obesidad, parálisis, etc. precisen sus servicios, encaminados a tratar los músculos, tendones, ligamentos, articulaciones y sistema arterio-venoso, basándose en la aplicación de las correctas manipulaciones necesarias para cada caso.</p>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<ul>
						<li>Gimnasia manual.</li>
						<li>Condiciones generales para efectuar el masaje.</li>
						<li>Manipulaciones y sus efectos.</li>
						<li>Efectos del masaje.</li>
						<li>Contraindicaciones del masaje.</li>
						<li>Dianóstico general.</li>
						<li>Diagnóstico orgánico y vertebral.</li>
						<li>Tratamiento de los tejidos blandos.</li>
						<li>Masaje deportivo.</li>
					</ul>
					<br/>
					<p><b>Tratamiento de las distintas zonas corporales:</b></p>
					<ul>
						<li>Espalda.</li>
						<li>Tórax.</li>
						<li>Cuello.</li>
						<li>Columna vertebral.</li>
						<li>Abdomen.</li>
						<li>Cintura escapular.</li>
						<li>Extremidades.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Octubre.</p>
						<p><b>Duración:</b> 12 meses. 380 horas.</p>
					</div>
					<div className='content_2_cols'>
						<p><b>Practicas:</b> 280 horas.</p>
						<p><b>Teoría:</b> 100 horas.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Modalidad de fin de semana (1 vez al mes)</p>
							<p>- Sábado de 10:00 a 14:00 y 16:00 a 20:00</p>
							<p>- Domingo de 10:00 a 14:00</p>
							<hr/>
							<p>Modalidad de semana</p>
							<p>- Jueves de 10:00 a 13:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}