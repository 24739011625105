import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Osteopatía Visceral</title>
					<meta name="description" content={'Curso de Osteopatía Cráneo-Sacra'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE OSTEOPATÍA VISCERAL</h2>
				<div className='image-grid'>
					<div><img src='/media/img/visceral/1.jpg' alt='image 1' /></div>
					<div><img src='/media/img/visceral/2.jpg' alt='image 2' /></div>
					<div><img src='/media/img/visceral/3.jpg' alt='image 3' /></div>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<ul>
						<li>El diafragma torácico, ese enigma.</li>
						<li>Las grandes responsabilidades del diafragma.</li>
						<li>Relaciones entre los tres grandes diafragmas.</li>
						<li>Osteopatía vesceral: las generalidades.</li>
						<li>Principios directores de la osteopatía.</li>
						<li>Sistema nervioso vegetativo.</li>
						<li>Relaciones entre metámeras y vísceras.</li>
						<li>Patologías de los movimientos viscerales.</li>
						<li>Los efectos psico-somáticos.</li>
						<li>Sistema aponeurótico del cuello y vainas viscerales.</li>
						<li>Sistema ajustador del hueso hioides y de la laringe.</li>
						<li>La faringe.</li>
						<li>Anatomo-fisiología. Su acción sobre la base craneal y sobre el diafragma craneal.</li>
						<li>El sistema laríngeo.</li>
						<li>Técnicas de rearmonización específicas de los elementos de la laringe.</li>
						<li>El sistema lingual.</li>
						<li>Anatomo-fisiología, relaciones directas.</li>
						<li>Funciones en la fonación y en la deglución.</li>
						<li>La región palatina.</li>
						<li>La mandíbula.</li>
						<li>Desarrollo de los siguientes temas:
							<ul>
								<li>A propósito del temporal, el "hacedor de transtornos".</li>
								<li>Disfunciones osteopáticas en el origen de las patologías de la expresión.</li>
							</ul>
						</li>
						<li>Glándulas tiroides y paratiroides.</li>
						<li>Anatomo-fisio-patología.</li>
						<li>Tratamiento osteopático.</li>
						<li>Las cavidades torácico-pleurales y el sistema bronco-pulmonar.</li>
						<li>La función respiratoria, sus funciones y sus tratamientos osteopáticos.</li>
						<li>El pericardio y sus relaciones diafragmáticas.</li>
						<li>Las bombas cardiacas, la bomba diafragmática y el sistema hemodinámico.</li>
						<li>Patologías del sistema, examen y tratamientos osteopáticos.</li>
						<li>La cavidad peritoneal.</li>
						<li>La columna visceral abdominal.</li>
						<li>El tubo digestivo: ana-tomo-fisiología.</li>
						<li>tratamientos osteopáticos de las disfunciones del sistema digestivo: generalidades.</li>
						<li>Relaciones de contigüidad y de continuidad.</li>
						<li>Topografía, puntos de referencia.</li>
						<li>Superficies de deslizamiento y medios de fijación.</li>
						<li>Movilidad y motilidad.</li>
						<li>Examen y tratamientos específicos.</li>
						<li>La faringe, el esófago y el estómago.</li>
						<li>El síndrome o la enfermedad esófago-cardio-vagal.</li>
						<li>El duodeno, asas del intestino delgado y el colon.</li>
						<li>El hígado, la vesículabiliar y el bazo.</li>
						<li>Las vías biliares y pancreáticas.</li>
						<li>El sistema de porta hepático.</li>
						<li>El sistema renal.</li>
						<li>El riñón anormalmente móvil o prolapsado.</li>
						<li>La tensión arterial.</li>
						<li>Los dolores viscerales.</li>
						<li>La organización de la cavidad pélvica.</li>
						<li>El diafragma pélvico.</li>
						<li>Las cavidades viscerales y su contenido en el hombre y la mujer.</li>
						<li>Examen y tratamientos osteopáticos.</li>
						<li>Clínica y diagnóstico diferencial en ginecología.</li>
						<li>Campo de acción de la osteopatía.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
					<p><b>Comienzo:</b> Ciclo continuo.</p>
					<p><b>Duración:</b> 8-12 meses (aprox.) 96 horas.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Modalidad de fin de semana (1 vez al mes)</p>
							<p>- Sábado de 10:00 a 14:00 y 16:00 a 20:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}