import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Medicina Tradicional China</title>
					<meta name="description" content={'Curso de Medicina Tradicional China'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE MEDICINA TRADICIONAL CHINA</h2>
				{/* <div className='image-grid'>
					<div><img src='/media/img/articular/1.jpg' /></div>
					<div><img src='/media/img/articular/2.jpg' /></div>
					<div><img src='/media/img/articular/3.jpg' /></div>
					<div><img src='/media/img/articular/4.jpg' /></div>
					<div><img src='/media/img/articular/5.jpg' /></div>
				</div> */}
				<div className="content-block">
					<p>La acupuntura y la moxibustión de la Medicina Tradicional China se encuentran en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad de la UNESCO, por su gran eficiencia y eficacia a la hora de afrontar cualquier desequilibrio que pueda presentarse en la salud de los pacientes.</p>
					<p>Es también un referente cultural del pensamiento humano y, como tal, queremos presentarlo en la Escuela Quimas.</p>
					<p>En este curso aprenderás los Fundamentos del pensamiento oriental, el Diagnóstico como base primordial del éxito y la Aplicación Terapéutica de las distintas técnicas de la Medicina China.</p>
					<p>Siempre siguiendo los tres pilares fundamentales de nuestra visión:</p>
					<ul>
						<li>El respeto a la tradición</li>
						<li>El respeto hacia el paciente</li>
						<li>El respeto hacia uno mismo</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<p><b>PRIMER AÑO</b></p>
					<ul>
						<li>INTRODUCCIÓN: Fundamentos e Historia de la Medicina Tradicional China.</li>
						<li>TEORÍA DEL YIN - YANG.</li>
						<li>WU XING: 5 Movimientos.</li>
						<li>SUSTANCIAS VITALES: 3 Tesoros :Jing, Qi, Xue.</li>
						<li>ZANG - FU: Fisiología de los órganos y su relación con los 5 Elementos.</li>
						<li>ENERGÍAS PERVERSAS Y 7 PASIONES.</li>
						<li>FORMACIÓN DEL QI.</li>
						<li>TÉCNICA: Aguja filiforme, lanceta, martillo flor de cerezo, chinchetas, moxa, ventosas.</li>
						<li>PRÁCTICA MANUAL.</li>
					</ul>
					<p><b>SEGUNDO AÑO</b></p>
					<ul>
						<li>ETIOLOGÍA: Constitución, estilo de vida, energías perversas, 7 pasiones.</li>
						<li>DIAGNÓSTICO: Observación, audición, olfación, palpación, interrogatorio.</li>
						<li>Columna lumbar: *El sacro.*La pelvis.</li>
						<li>DIFERENCIACIÓN DE SÍNDROMES Y PRINCIPIOS TERAPÉUTICOS: Principios y reglas terapéuticas, 8 reglas, 4 capas, 6 niveles, Qi - Xue, meridianos.</li>
						<li>SISTEMA DE LOS MERIDIANOS: Tipos de canales, capas energéticas, circulación energética, horario y tipos de qi.</li>
						<li>MERIDIANOS: Yin de la mano (P, MC, C), Yin del pie (R, H, B), Yang de la mano (IG, TR, ID), Yang del pie (E, VB, V), Meridianos Curiosos, Meridianos Secundarios.</li>
						<li>PUNTOS: Puntos Shu Antiguos (Jing, Rong, Shu, King, He, Ben), Puntos Yuan, Luo, Xi, Puntos Shu de espalda, Punto Mu, Puntos Diablo, Puntos Curiosos.</li>
					</ul>
					<p><b>TERCER AÑO</b></p>
					<ul>
						<li>APLICACIÓN TERAPÉUTICA.</li>
						<li>MERIDIANOS TENDINO - MUSCULARES.</li>
						<li>ZONAS CUTÁNEAS.</li>
						<li>SÍNDROMES BI - WEI: El Zhong Feng.</li>
						<li>TRATAMIENTO DEL DOLOR: Muscular, articular, cervical, cefalea.</li>
						<li>MICROSISTEMAS: Auriculopuntura, Craneopuntura.</li>
						<li>INTRODUCCIÓN A LA DIETOTERAPIA CHINA.</li>
						<li>PRÁCTICA CLÍNICA.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Octubre-Julio.</p>
						<p><b>Duración:</b> 30 meses.</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Horario de mañanas:</p>
							<p>- Viernes de 10:00 a 14:00</p>
							<hr/>
							<p>Horario de tardes:</p>
							<p>- Viernes de 16:00 a 20:00</p>
							<hr/>
							<p>Intensivo fin de semana:</p>
							<p>- Un Sábado al mes: De 09:00 a 14:00 y de 15:00 a 20:00</p>
							<p>- Un Domingo: De 09:00 a 14:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}