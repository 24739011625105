import { isUndefined } from 'util';
import { isServer } from './Utils';


export const getCookie = (cname,defaultValue)  =>
{
	let name = cname + "=";
	let ca = '';
	if(!isServer)
	{
		ca = document.cookie.split(';');
		for(let i=0; i<ca.length; i++)
		{
			let c = ca[i];
			while (c.charAt(0)===' ') c = c.substring(1);
			if (c.indexOf(name) !== -1)
			{
				return c.substring(name.length, c.length);
			}
		}
		return defaultValue;
	}
	else if(!isUndefined(global.requestCookies))
	{
		let ca = {...global.requestCookies};
		return typeof ca[cname] !== 'undefined' ? ca[cname] : defaultValue;
	}
	else
	{
		return defaultValue;
	}
}

export const setCookie = (cname, cvalue, exhours) =>
{
	let d = new Date();
	d.setTime(d.getTime() + (exhours*60*60*1000));
	let expires = "expires="+ d.toGMTString();
	if(!isServer)
	{
	
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	else
	{
		global.responseCookie(cname,cvalue,{})
	}
}
