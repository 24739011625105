import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Otros módulos y especialidades</title>
					<meta name="description" content={'Curso de Osteopatía Cráneo-Sacra'} />
				</Helmet>
				<h2 className='header-title filled'>Otros módulos y especialidades</h2>
				<div className='image-grid'>
					<div><img src='https://www.formacion-dka.es/images/curso-online-nutricion-dietetica.jpg' alt='image 1' /></div>
					<div><img src='http://www.radiologia-salud.es/wp-content/uploads/2015/09/Aparato-Locomotor-en-Radiolog%C3%ADa.jpg' alt='image 2' /></div>
					<div><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzgCcTIomwnwx0DZhBKG7HLuCirAK8GWPglGlHYLDYMR9kKmoU2A' alt='image 3' /></div>
					<div><img src='http://ficemu.org/wp-content/uploads/2015/09/esclerosis_multiple.jpg' /></div>
				</div>
				<div className='content-block'>
					<p>Ofrecemos también formación en otros módulos y especialidades.</p>
					<ul>
						<li>Dietética y Nutrición</li>
						<li>Radiología Básica del Aparator Locomotor</li>
						<li>Adecuación Respiratoria</li>
						<li>Patología Neurológica General</li>
					</ul>
					<Link to='/contacto'><button className='button'>Consultar para más información</button></Link>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}