import React from "react";
import Helmet from 'react-helmet';

import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';

import { HashLink as Link } from "react-router-hash-link";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}

	render()
	{
		return (

			<div className='slim-content course-page'>
				<Helmet>
					<title>Curso de Osteopatía Articular</title>
					<meta name="description" content={'Curso de Osteopatía Articular'} />
				</Helmet>
				<h2 className='header-title filled'>CURSO DE OSTEOPATÍA ARTICULAR</h2>
				<div className='image-grid'>
					<div><img src='/media/img/articular/1.jpg' alt='image 1' /></div>
					<div><img src='/media/img/articular/2.jpg' alt='image 2' /></div>
					<div><img src='/media/img/articular/3.jpg' alt='image 3' /></div>
					<div><img src='/media/img/articular/4.jpg' alt='image 4' /></div>
					<div><img src='/media/img/articular/5.jpg' alt='image 5' /></div>
				</div>
				<div className="content-block">
					<p>La osteopatía articular, es un conjunto de técnicas científicamente desarrolladas, cuya función principal es dotar de movilidad, a aquellas partes del cuerpo carentes o limitadas de ella.</p>
					<p>En este exhaustivo curso, se enseñará la correcta aplicación, del gesto manipulativo sobre las estructuras afectadas del aparato músculo-esquelético.</p>
					<p>Cada una de las técnicas osteopáticas, será analizada con minuciosidad, como queda perfectamente reflejado en el siguiente programa.</p>
				</div>
				<div className="content-block">
					<h3 className="header-title">Programa</h3>
					<p><b>CONCEPTOS Y PRINCIPIOS DE OSTEOPATÍA</b></p>
					<ul>
						<li>La anatomofisiologia.</li>
						<li>Las lesiones osteopáticas.</li>
						<li>Las técnicas de diagnóstico y exploración.</li>
						<li>Leyes de comportamiento.</li>
						<li>Tratamiento de los tejidos blandos.</li>
						<li>Técnicas  de normalización.</li>
					</ul>
					<p><b>DE LAS SIGUIENTES ZONAS DEL CUERPO:</b></p>
					<ul>
						<li>Columna cervical y articulación temporomandibular.</li>
						<li>Columna dorsal y costillas.</li>
						<li>Columna lumbar: *El sacro.*La pelvis.</li>
						<li>Extremidad superior.</li>
						<li>Extremidad inferior.</li>
					</ul>
				</div>
				<div className="content-block">
					<h3 className="header-title">Contenido en horas</h3>
					<p>Las materias teóricas y prácticas del curso, están desarrolladas en 7 tomos con mas de 1.000 páginas y 1.000 esquemas y fotografías, tanto de anatomía como de patología así como de las técnicas de diagnóstico osteopático y normalización.</p>
					<p>La duración del curso es de 800 horas, (lectivas, exámenes y repasos) divididas en clases prácticas y en conocimientos técnicos.</p>
				</div>
				<div className="content-block">
					<h3 className="header-title">Tratamientos más comunes</h3>
					<p className='tags'>
						<span>Artrosis</span><span>Lesiones discales</span><span>Ciatalgias</span><span>Lumbalgias</span><span>Esguinces</span><span>Pies planos</span><span>Cavos</span>
						<span>Osteopatía de pubis</span><span>Dolencias de las escoliosis</span><span>Dorsalgias</span><span>Dolor intercostal</span><span>Cefaleas</span><span>Migrañas</span>
						<span>Mareos</span><span>Tendinitis</span><span>Bursitis</span><span>Control en el deporte</span><span>Elastificación máxima articular</span>
					</p>
				</div>
				<div className="content-block">
					<h3 className="header-title filled">Información detallada</h3>
					<div className='content_2_cols'>
						<p><b>Comienzo:</b> Ciclo continuo.</p>
						<p><b>Duración:</b> 32 meses (aprox.)</p>
					</div>
					<div className='content_2_cols'>
						<div>
							<h4 className="header-title">Horarios</h4>
							<p>Modalidad de fin de semana (1 vez al mes)</p>
							<p>- Sábado de 10:00 a 14:00 y 16:00 a 20:00</p>
							<p>- Domingo de 10:00 a 14:00</p>
							<hr/>
							<p>Modalidad de semana</p>
							<p>- Martes de 10:00 a 13:00</p>
						</div>
						<div>
							<h4 className="header-title">Precio</h4>
							<Link to='/contacto'><button className='button'>Consultar</button></Link>
						</div>
					</div>
				</div>
				<div className='share-block'>
					<h3>Compartir curso</h3>
					<FacebookShareButton url={window.location.href} quote={window.title} className="share-button">
						<FacebookIcon size={64} />
					</FacebookShareButton>
					<TwitterShareButton url={window.location.href} title={window.title} className="share-button">
						<TwitterIcon size={64} />
					</TwitterShareButton>
					<WhatsappShareButton url={window.location.href} title={window.title} className="share-button">
						<WhatsappIcon size={64} />
					</WhatsappShareButton>
				</div>
			</div>
		);
	}
}