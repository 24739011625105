import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faCode, faCamera } from '@fortawesome/free-solid-svg-icons';
import "./footer.scss"
export class Footer extends React.Component
{
	static contextTypes  = {
    lang: PropTypes.string,
    defaultLang: PropTypes.string,
	}

	static propTypes =
	{
		copyrightHolder: PropTypes.string.isRequired,
	};


	render()
	{
		let d = new Date();

		return (
			<div className="footer">
				<ul>
					<li ><span> {this.props.copyrightHolder} </span><FontAwesomeIcon icon={faCopyright} /><span> {d.getFullYear()}</span></li>
					{this.getAuthor()}
					{typeof this.props.unsplash !== 'undefined' &&
						<li ><a href={"https://unsplash.com/collections/"+this.props.unsplash} rel="noopener noreferrer" target="_blank" ><FontAwesomeIcon icon={faCamera} /><span> <span style={{fontSize:"11px"}} >@</span> unsplash</span></a></li>
					}
					{this.props.children}
				</ul>
			</div>
		);
	}

	getAuthor()
	{
		if(this.props.copyrightHolder!=="System3")
		{
			//eslint-disable-next-line react/jsx-no-target-blank
			return (<li ><a href="http://www.system3.es" target="_blank" ><FontAwesomeIcon icon={faCode} /><span> by System3</span></a></li>);
		}
	}
}