import React from 'react';
import { listifyChildren } from "./MenuUtils";

export class SubMenu extends React.Component
{
	render()
	{
		let {children, className="", vertical=false, label, labelClass="", ...props} = this.props;
		labelClass+=" has-submenu"
		className+=vertical?"menu nested vertical":"menu nested"
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		return ( <li className={labelClass}>  <a>{label}</a>
				<ul {...props} className={className} data-submenu>
					{listifyChildren(children)}
				</ul>
			</li>
		);
	}
}