import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { isServer } from '../tools/Utils';
import { initializeState, getReducers, getEnhancers, getMiddleware } from './storeCongfig';

export default (url = '/') => {

  const enhancers = getEnhancers();

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [...getMiddleware()];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = initializeState();

  console.log(initialState);
  
	
  let reducers = getReducers()

	if(Object.keys(reducers).length>0)
	{
		reducers = combineReducers(reducers);
	}
	else
	{
		reducers = ()=>{};
	}

  // Create the store
  return createStore(
    reducers,
    initialState,
    composedEnhancers
  );
};
