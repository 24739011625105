import React from "react";

import logo from "../../assets/logo_A.png";
import promo1 from "../../assets/promo1.jpg";
import promo2 from "../../assets/promo2.jpg";

export default class extends React.Component
{
	componentDidMount()
	{
		window.scrollTo(0, 0);
	}
	
	render()
	{
		return (
			<div className='home-page'>
				<div className='slim-content'>
					<div className='slogan'>
						<img src={logo} />
						{/* <span>Quimas</span> */}
						<h2>Escuela Politécnica Homologada por Fedine</h2>
						<h2>Centro de masaje, osteopatía y tratamiento articular</h2>
						<br/>
						<h3>Nueva ubicación Avenida de Castrelos 55</h3>
					</div>
					{/* <div className='promos content_2_cols'>
						<div>
							<h3>Formación</h3>
							<img src={promo1} alt='promo image 1' />
						</div>
						<div>
							<h3>Terapias</h3>
							<img src={promo2} alt='promo image 2' />
						</div>
					</div> */}
				</div>
			</div>
		);
	}
}