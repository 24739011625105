import { isServer } from "../tools/Utils";
import thunk from "redux-thunk";
export function getReducers()
{
  return {};
}
export function initializeState () 
{
	const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }
  
  return initialState;
}

export function getEnhancers()
{
  return [];
}

export function getMiddleware()
{
  return [thunk]
}